.Pagination {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  font-size: 12px;
}

.Pagination__item {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 2px;
}

.Pagination__item:not(.Pagination__item--disabled):hover {
  background: rgba(0, 0, 0, 0.03);
}

.Pagination__item:not(.Pagination__item--disabled):active {
  background: rgba(0, 0, 0, 0.06);
}

.Pagination__item a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0;
}

.Pagination__item svg {
  color: rgb(222, 28, 18);
  margin-top: -2px;
}

.Pagination__item--active {
  font-weight: 500;
  color: rgb(222, 28, 18);
}

.Pagination__item--disabled {
  color: #888;
}

.Pagination__item--disabled svg {
  color: #bebebe;
}

.Pagination__item--disabled a {
  cursor: unset;
}

.Pagination__goto-label {
  margin-right: 11px;
  color: #000000;
}
